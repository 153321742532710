import Poller from "shared/services/polling/Poller";
import { useAlertsStore } from "shared/stores/alerts";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

import setApplicationIconBadgeNumber from "src/helpers/setApplicationIconBadgeNumber";

async function callback() {
  const { fetchAlertCount } = useAlertsStore();

  if (!useUserStore().isLoggedIn || useGlobalStore().technicalServerError) {
    return;
  }

  const count = await fetchAlertCount();
  setApplicationIconBadgeNumber(count);
}

export default new Poller({ callback });
