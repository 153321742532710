import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { initializeApp } from "@firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithCredential,
  signOut,
} from "@firebase/auth/cordova";

import { HOUR_IN_MILLISECONDS } from "shared/constants";
import Poller from "shared/services/polling/Poller";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

let googleProvider;
let firebaseApp;
let firebaseAuth;

const ready = new Promise((resolve) => {
  document.addEventListener("deviceReady", resolve);
});

async function initializeFirebase() {
  await ready;

  if (!googleProvider) {
    googleProvider = new GoogleAuthProvider();
    firebaseApp = initializeApp(useGlobalStore().appConfig.firebaseAppConfig);
    firebaseAuth = getAuth(firebaseApp);
    await setPersistence(firebaseAuth, indexedDBLocalPersistence);
  }
}

function initializeGoogleAuth() {
  GoogleAuth.initialize();
}

export const loginWithGoogle = async () => {
  initializeGoogleAuth();
  await initializeFirebase();

  return new Promise((resolve, reject) => {
    GoogleAuth.signIn()
      .then(async (response) => {
        try {
          const {
            authentication: { idToken: oAuthIdToken },
            email,
          } = response;

          const credential = GoogleAuthProvider.credential(oAuthIdToken);
          const { user } = await signInWithCredential(firebaseAuth, credential);
          const idToken = await user.getIdToken();

          resolve({ email, idToken });
        } catch (error) {
          reject(error);
        }
      })
      .catch(reject);
  });
};

export const refreshGoogleLogin = async () => {
  initializeGoogleAuth();
  await initializeFirebase();

  try {
    const idToken = await firebaseAuth.currentUser.getIdToken(true);

    await useUserStore().refreshGoogleSsoCallback({
      idToken,
      client: "web",
    });
  } catch {
    signOut(firebaseAuth);
    useUserStore().logout();
  }
};

export const signOutGoogle = async () => {
  GoogleAuth.signOut();

  if (firebaseAuth.currentUser) signOut(firebaseAuth);
};

export const GoogleLoginPoller = new Poller({
  callback: refreshGoogleLogin,
  interval: HOUR_IN_MILLISECONDS,
});
