import { App } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { CapacitorUpdater } from "@capgo/capacitor-updater";

CapacitorUpdater.notifyAppReady();

let data = null;

const initCodePush = async () => {
  App.addListener("appStateChange", async (state) => {
    if (state.isActive) {
      // Do the download during user active app time to prevent failed download
      try {
        const latest = await CapacitorUpdater.getLatest();

        if (latest.url) {
          data = await CapacitorUpdater.download({
            url: latest.url,
            version: latest.version,
          });
        }
      } catch {
        // No update to install or failed to download; do nothing
      }
    }

    if (!state.isActive && data) {
      // Do the switch when user leave app or when you want
      await SplashScreen.show();

      try {
        await CapacitorUpdater.set({ id: data.id });
      } catch (err) {
        SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
      }
    }
  });
};

// eslint-disable-next-line
export { initCodePush };
