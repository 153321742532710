/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config file > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/ionicons-v4.js'



import {QAvatar,QBanner,QBtn,QBtnDropdown,QCarousel,QCarouselSlide,QCheckbox,QChip,QExpansionItem,QColor,QDialog,QMenu,QIcon,QInfiniteScroll,QImg,QInnerLoading,QInput,QItem,QItemLabel,QItemSection,QDrawer,QHeader,QList,QPage,QPageContainer,QPullToRefresh,QPopupProxy,QRadio,QSelect,QSeparator,QSlider,QSlideTransition,QSpinner,QSpinnerDots,QToggle,QToolbar,QToolbarTitle,QVideo,QLayout,QCard,QCardSection,QCardActions,QRange,QTooltip,ClosePopup,Intersection,Ripple,BottomSheet,Loading,Notify,Dialog} from 'quasar'



export default { config: {},iconSet,components: {QAvatar,QBanner,QBtn,QBtnDropdown,QCarousel,QCarouselSlide,QCheckbox,QChip,QExpansionItem,QColor,QDialog,QMenu,QIcon,QInfiniteScroll,QImg,QInnerLoading,QInput,QItem,QItemLabel,QItemSection,QDrawer,QHeader,QList,QPage,QPageContainer,QPullToRefresh,QPopupProxy,QRadio,QSelect,QSeparator,QSlider,QSlideTransition,QSpinner,QSpinnerDots,QToggle,QToolbar,QToolbarTitle,QVideo,QLayout,QCard,QCardSection,QCardActions,QRange,QTooltip},directives: {ClosePopup,Intersection,Ripple},plugins: {BottomSheet,Loading,Notify,Dialog} }

